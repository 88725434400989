.text-primary{
  color: #5a8dee!important;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
#navbar{
  border-bottom: 1px solid #dbdbdb;
}
.sidebar-list {
  display: flex;
}
.sidebar{
    width: 19em;
    min-height: -moz-fit-content;
    min-height: fit-content;
    background-color: #fff;
    position: fixed;
    top: 5em;
    right: -100%;
    z-index: 1;
    transition: 0.5s;
    border-radius: 10px;
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.25);
}
.sidebar.active{
    right: 0.7em;
    z-index: 3;
}
.sd-header{
    display: flex;
    justify-content: center;
    padding: 1em;
    padding-top: 2em;
    background-color: #eb262b33;
    border-radius: 10px 10px 0px 0px;
    height: 90px;
}
.sd-header-icon{
  position: relative;
  bottom: -15px;
}

.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar-overlay.active{
    z-index: 2;
    opacity: 1;
    visibility: visible;
}
.sd-body{
  margin-top: 2em;
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sd-body-header{
  font-size: 1.2rem;
  font-weight: 600;
}
.sd-body-subheader{
  font-size: 0.9rem;
  font-weight: 500;
}
.sd-footer-btn{
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #dadce0;
  cursor: pointer;
}
.sd-footer-btn:hover{
  background-color: #efefef;
}
.sd-footer-btn .sd-link{
  margin-left: 10px;
}
.navbar-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  margin: 1vh 1vw;
}
.header-logo img {
  height: 7vh;
}
.header-user {
  margin: 1vh 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #dadce0; */
  padding: 0.7rem;
  border-radius: 1rem;
  cursor: pointer;
}
.header-user-text{
  opacity: 0;
  margin:0px  10px;
  text-align: end;
  transition: 0.5s;
}
.header-user:hover .header-user-text{
  opacity: 1;
}
.header-user:hover{
  background-color: #efefef;
}

.header-bottom {
  overflow: hidden;
  background-color: transparent;
}
.header-bottom img {
  width: 100%;
  height: 5px;
  background: transparent;
  display: block;
}
.navbar-wrapper-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.navbar-wrapper {
  margin: 0.5rem 0rem;
  width: 100%;
}
.navbar-assignment-options{
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-sortby-list {
  display: flex;
  list-style: none;
  justify-content: center;
  /* margin-right: 1vw; */
  margin: 0rem 0.6rem;
  /* flex: 1; */
  align-items: center;
  
}
.navbar-sortby-list-item {
  text-decoration: none;
  padding: 0.3rem 1rem;
  margin: 0.2rem 0rem;
  color: black;
  font-weight: 500;
  border-radius: 0.6rem;

  cursor:pointer;
}
.navbar-sortby-list-item:hover {
    /* background-color: #faca2c; */
    -webkit-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);  
}
.navbar-header-menu-icon-1,
.navbar-header-menu-icon-2 {
  display: none; 
}

.students-container-list-menu-toggle{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
.students-container-list-menu-toggle-icon{
  background-color: #faca2c;
  border-radius: 50%;
}
@media only screen and (max-width: 768px) {
  .navbar-header {
    justify-content: space-between;
  }
  .header-user-text {
    display: none;
  }
  .navbar-header-menu-icon-1 {
    display: block;
    margin: 1rem;
  }
  .navbar-header-menu-icon-2 {
    color: white;
    display: block;
  }
}
.navbar-assignment-autocheck{
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  cursor: pointer;
  padding: 0.3rem 1rem;
  background-color: #eb262b33;
  border-radius: 0.6rem;
  transition: 0.5s;
}
.navbar-assignment-autocheck:hover{
  -webkit-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);  
}

.navbar-analysis {
  margin-right: 1rem;
  border-radius: 0.6rem;
}

.navbar-analysis-item {
  padding: 0.4rem 1rem;
  margin: 0.2rem 0rem;
  background-color: #ffd1d1f2;
  color: #892323;
  border-radius: 0.6rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s;
}

.navbar-analysis-item:hover {
  -webkit-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  -moz-box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 15%);
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 25%); 
  background-color: #ffb5b5;
}
.students-container {
    display: flex;
    height: 83vh;
    /* border-top: 1px solid #ffaf1d; */
}

.students-container-list-eval {
    width:9rem;
    /* height: 83vh; */
    opacity: 1;
    border-right: 1px solid #efefef;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    height:100%;
    /* overflow-y: auto; */

}
.students-container-list-child{
    height: 73vh;
    overflow-y: auto;
}
.students-container-details {
    /* margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center; */
    overflow-y: scroll;
    width: 100%;
    background-color: white ;
}

.students-container-list-eval.hide {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.students-container-list-options{
    padding: 1.125rem 1rem;
    border-bottom: 0.6px solid #dbdbdb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.students-container-list-toggle-eval{
    margin-left: 1rem;
}
.students-container-list-toggle-icon-eval{
    background-color: #ffbabc;
    border-radius: 50%;
}
.students-container-list-toggle-hidden-menu-eval{
    position: relative;
    height: 100%;
    width: 1.75rem;
    top: 2rem;
    border-right: 1px solid #efefef;
}
@media only screen and (max-width: 375px) {
    .hide-425px {
        display: none;
    }

    .students-container-list {
        width: 100%;
    }
}

.student {
    padding: 1rem 1.5rem;
    background-color: white;
    color: black;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 4px solid transparent;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}

.student:hover {
    background-color: #efefef;
}

.students-active {
    padding: 1rem 1.5rem;
    background-color: white;
    color: black;
    /* border-top: 0.6px solid #ffaf1d; */
    /* border-bottom: 0.6px solid #ffaf1d; */
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: 1px dotted #ffaf1d;
    /* border-left: 4px solid #ffaf1d; */
    /* border-right: 4px solid #ffaf1d; */
    background-color: #ffbd4424;
    cursor: pointer;
}

.student-station {
    font-weight: 300;
    font-size: small;
}

.student-countdown {
    width: fit-content;
    text-align: justify;
    font-weight: 500;
    border-radius: 1em;
    transition: 1s;
    -webkit-transition: 1s;
    margin-bottom: 5px;
}
.student-countdown-icon {
    font-size: small;
    /* border-radius: 50%; */
    overflow: hidden;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* background-color: #ffaf1d; */
}

.student-StationOptions {
    /* background-color: #ffbd4424; */
    padding: 3px 5px;
    border-radius: 0.3rem;
}

.list-element-time-container{
    border-radius: 10px;
}

.list-element-time-container-child{
    padding: 1rem;
}
.student-details-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.student-details-container-1{
    width: 100%;
}
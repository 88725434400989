.question_form {
    height: 83vh;
    background-color: #f9f9f9;
    overflow-y: auto;
}

.question_form .section {
    margin:1rem auto;
    width: 50%;
    
}

.question_form_top {
    background-color: white;
    border-top: 8px solid rgb(255, 224, 121);
    border-radius: 8px;
    padding: 30px 25px;
    text-transform: capitalize;
}

.question_form_top_name {
    box-sizing: border-box;
    font-family: 'Google Sans', Roboto, Arial, sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    line-height: 135%;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #f4f4f9;
    color: black;
    height: 35px;
}

.question_form_top_desc {
    box-sizing: border-box;
    margin-top: 10px;
    font-family: 'Google Sans', Roboto, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 40px;
    width: 100%;
    border: none;
    outline: none;
    border-bottom: 1px solid #f4f4f9;
    color: black;
    height: 20px;
}

.question_boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.add_question {
    background-color: white;
    border-radius: 8px;
    padding: 25px 22px;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    width: 93%;
    margin-left: 10px;
}
.add_question_upload{
    width: 100px;
    margin-bottom: 20px;
}
.add_question_top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem 0rem;
}

.question {
    /* box-sizing: border-box;
    margin-top: 10px;
    font-family: 'Google Sans', Roboto, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    flex: 1;
    line-height: 40px;
    width: 40%;
    border: none;
    outline: none;
    color: black;
    height: 40px;
    margin-right: 10px;
    line-height: 10px;
    padding: 10px; */
    /* margin: 1rem 0rem; */
    margin-right: 0.5rem;
    /* margin-bottom: 2rem; */
    width:100%;
}

.question:focus {
    border-bottom: 1px solid rgb(103, 58, 183);
    background-color: #f4f4f9;
}

.select {
    height: 40px;
    width: 230px;
    padding: 5px 15px;
    color: black;
    margin-left: 10px;
    margin-right: 10px;
    border: 1.5px solid #f4f4f9;
    border-radius: 3px;
    background: transparent;
}

.menuitem {
    color: rgba(118, 118, 118, 0.3);
}

.add_question_body {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.text_input {
    outline: none;
    border: none;
    height: 40px;
    width: 490px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #202124;
    margin:0px 10px;
}
.add_option{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:5px 10px;
    margin:5px 0px;
    cursor: pointer;
    border-radius:5px;
    border: 1px solid #dadce0;
    color: #757575;
    margin-top: 20px;
}
.add_option:hover{
  background-color: #efefef;

}
.text_input:focus {
    border-bottom: 1.5px solid rgb(103, 58, 183);
}

.add_footer {
    display: flex;
    justify-content: space-between;
}

.add_question_bottom {
    margin-top: 12px;
    /* border-top: 1px solid rgb(118, 118, 118, 0.3); */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.add_question_bottom_left {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.add_border {
    border-left: 6px solid #4285f4;
}

.question_edit {
    background-color: #f4f4f9;
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 1px;
    border-radius: 3px;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.edit {
    color: #5f6368;
    padding: 1px 3px;
}

.edit:hover {
    cursor: pointer;
    color: black;
    padding: 1px 3px;
}
*{
    font-family: 'Source Sans Pro', sans-serif;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  /* * {
    scrollbar-width: thin;
    scrollbar-color: #ffc37d #ffffff;
  } */

  /* Chrome, Edge, and Safari */
  /* *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ffc37d;
    border-radius: 10px;
    border: 3px solid #ffffff;
  } */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #C5C5C5 #EFEFEF;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 8px;
  width: 8px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #EFEFEF;
}

*::-webkit-scrollbar-track:hover {
  background-color: #EFEFEF;
}

*::-webkit-scrollbar-track:active {
  background-color: #EFEFEF;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #C5C5C5;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #C5C5C5;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #AFAFAF;
}
.view-flex {
    display: flex;
    margin-top: 30px;
    margin-left: 50px;
    /*justify-content: center;*/
    align-items: center;
}

.announcement-crud-options {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.announcement-download {
    display: flex;
    cursor: pointer;
    margin: 0rem 0.5rem;
    justify-content: end;
}

.announcement-download:hover {
    color: black;
    cursor: pointer;
}

.popup-content {
    border-radius: 2rem;
    padding: 0px !important;
    background-color: #f9f9f9 !important;

}

.popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: white;
    border-radius: 1rem;
    float: right;
    cursor: pointer;
}

.announcement-card-content {
    border-radius: 2rem;
    padding: 2rem;
}

.announcement-container {
    padding: 2rem;
    /* border-top: 1px solid #ffaf1d; */
    height: 84vh;
    background-color: #f9f9f9;
    overflow-y: scroll; /* Add the ability to scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.announcement-container::-webkit-scrollbar {
    display: none;
}

.announcement-active-details {
    width: 40rem;
}

.announcement-popup-border-fix {
    border-radius: 2rem 2rem 0rem 0rem;
}

.announcement-card {
    min-width: 12rem;
    height: 200px;
    border-radius: 2rem;
    padding: 35px;
    overflow: hidden;
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
    text-overflow: ellipsis;
    background-color: white;
}

.announcement-card-popup {
    min-width: 12rem;
    max-height: 50vh;
    border-radius: 2.5rem;
    padding: 2rem;
    overflow: hidden;
    -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    text-overflow: ellipsis;
}

.announcement-card-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(129 129 129);
}

.announcement-card-crud-options {
    display: flex;
    align-items: center;
}

.announcement-card-heading {
    margin: 0.5rem 0rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
}

.announcement-card-body {
    overflow: hidden;
    height: fit-content;
    margin-bottom: 45px;
}

.announcement-card-body p {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
}

.announcement-card-heading-popup {
    margin: 0.5rem 0rem;
    font-weight: bold;
    text-overflow: ellipsis;
    font-size: 1.2rem;
}

.announcement-card-body-popup {
    overflow: auto;
}

.announcement-card-attachment-popup{
    margin-top: 1rem;
}

.announcement-card-options-2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.announcement-card-read-more {
    background-color: rgb(212, 212, 212);
    display: inline;
    padding: 0.1rem 0.5rem;
    color: #7a7a7a;
    border-radius: 0.4rem;
}

.announcement-card-read-more:hover {
    background-color: #ffd1d1f2;
    color: #892323;
    cursor: pointer;
}

.add-announcement-button {
    background-color: #ffd1d1f2;
    color: #892323;
    cursor: pointer;
    position: absolute;
    padding: 1.3rem 2rem;
    border-radius: 1.5rem;
    font-size: 3rem;
    bottom: 2rem;
    right: 2rem;
    -webkit-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
}

.announce-details-empty {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 100%;
    width: 100%;
}

.announcement-heading {
    border: none;
}

.announcement-heading:focus {
    outline: none !important;
}

.announcement {
    padding: 1rem 1.5rem;
    background-color: white;
    color: black;
    border-bottom: 0.6px solid #ffaf1d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid transparent;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
}

.announcement-active {
    padding: 1rem 1.5rem;
    background-color: white;
    color: black;
    border-bottom: 0.6px solid #ffaf1d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid #ffaf1d;
    background-color: #ffbd4424;
    cursor: pointer;
}

.announcement-station {
    font-weight: 300;
    font-size: small;
}

.announcement-StationOptions {
    /* background-color: #ffbd4424; */
    padding: 3px 5px;
    border-radius: 0.3rem;
}

.announcement-icon-hover {
    margin: 0rem 0.25rem;
}

.announcement-icon-hover:hover {
    color: black;
}
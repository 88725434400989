.report-container {
  padding: 2rem;
  height: 84vh;
  background-color: #f9f9f9;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.report-container::-webkit-scrollbar {
  display: none;
}

.report-title {
  margin-bottom: 2rem;
  text-align: center;
}

.report-section {
  margin-bottom: 2rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.report-sections {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .report-sections {
    flex-direction: row;
    justify-content: space-between;
  }

  .report-section {
    width: 48%;
  }
}
.report-upload {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.file-name {
  margin-left: 1rem;
  /* font-size: 1.3rem;
  padding-top: 0.4rem; */
}

.submit-button {
margin-top: 0.3rem;
}

.file-info {
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
}
.report-status {
  margin-bottom: 1rem;
}

.status-submitted, .status-not-submitted {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  max-width: max-content;
}

.status-submitted {
  background-color: #e8f5e9;
}

.status-not-submitted {
  background-color: #ffebee;
}

.status-submitted span, .status-not-submitted span {
  margin-left: 0.5rem;
  font-weight: bold;
}

.template-upload-section {
  margin-top: 1rem;
  /* margin-bottom: 2rem; */
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}

@media (max-width: 600px) {
  .template-upload-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .template-upload-section button {
    margin-bottom: 1rem;
  }
}

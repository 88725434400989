.diary-student-profile {
    background: white;
    padding: 0rem 1.5rem;
    border-bottom: 1px solid #fff6e5;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: space-between;
    height: 9.5vh;
    align-items: center;
}

.diary-question-container {
    max-height: 73vh;
    overflow: auto;
    border-radius: 1rem;
    padding: 1.5rem;
}

.diary-question {
    margin-bottom: 1.5rem;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    border-radius: 15px;
    padding: 1rem;
}

.diary-question-1 {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.diary-question-1 p {
    padding: 1rem 0.5rem;
}

.diary-question-1-expandIcon:hover {
    cursor: pointer;
}

.diary-question-2-active {
    padding: 1rem;
    background-color: white;
    border-radius: 0.6rem;
    opacity: 1;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.diary-question-2-active.hide {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    position: absolute;
    bottom: -50px;
}

.diary-question-2-desc {
    display: flex;
    align-items: center;
    margin-top: 5px;
    position: relative;
    font-size: 0.9rem;
    color: #1976d2;
}

.diary-options {
    display: flex;
    justify-content: center;
    align-items: center;
}

.diary-options-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    background-color: #dadce0;
    border-radius: 0.5rem;
    padding: 0.3rem 0.8rem;
    transition: all 0.5s ease-in;
}

.diary-options-child:hover {
    background-color: #ffd1d1f2;
    color: #892323;
    cursor: pointer;
}

.diary-options-lock {
    padding: 0.5rem;
    margin: 1rem;
    transition: all 0.5s ease-in;
    border-radius: 0.5rem;
}

.diary-options-lock:hover {
    background-color: #ffd1d1f2;
    /* color: #892323; */
    cursor: pointer;
}

.grade-entry {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
}

.grade-input {
    width: 6rem;
}

.grade-input input {
    margin: 0rem;
}
.student-assignment-time {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    padding: 0.3rem 0.5rem;
    background-color: #eb262b33;
    border-radius: 0.6rem;
    transition: 0.5s;
}
  
.diary-question-correction-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

}
.diary-question-correct-answer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.6rem;
    background-color: #ffe8bf;
    border-radius: 0.6rem;
    transition: 0.5s;
}

.statistics-list-element-icon {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.3rem 1rem;
    background-color: #fff6e4;
    border-radius: 2rem;
    transition: 0.5s;
    font-weight: bold;
}
.admin-station-container-1{
    height: 100%;
    overflow: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* justify-content: center; */
    align-items: start;
    position: relative;
    overflow-y: scroll; /* Add the ability to scroll */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.admin-station-container-1::-webkit-scrollbar {
  display: none;
}

.avatar-name {
  display: flex;
  flex-direction: row;
  gap:2rem
}

  .email {
    font-size: 1.3rem;
    font-weight: 500;
  }
  
  .admin-station-parent-1 {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    gap: 2rem;
  }

  .admin-station-parent-2 {
    display: flex;
    flex-direction: row;
    align-items: start;
    font-size: 1.2rem;
    font-weight: 500;
    gap: 2rem;
  }
  
  .admin-station-table{
    width: 100%;
    border: 1px solid rgba(224, 224, 224, 1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    border-radius: 1rem;
    /* background: rgba(0,0,0,0.8); */
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
  }
  
  .station-info-1 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .station-name-id {
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .station-name-1 {
    font-weight: 700;
    font-size: 1.7rem;
    padding-bottom: 1.5rem;
  }
  
  .custom-shape-divider-bottom-1676796279 {
    /* position: absolute;
    bottom: 0;
    left: 0; */
    width: 100%;
    /* overflow: hidden; */
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -30em;
    z-index: -5;
  }
  
  .custom-shape-divider-bottom-1676796279 svg {
    position: relative;
    display: block;
    width: calc(133% + 1.3px);
    height: 196px;
  }
  
  .custom-shape-divider-bottom-1676796279 .shape-fill {
    fill: #FFBD4424;
  }
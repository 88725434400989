.file-upload-container{
    display: flex;
    max-width: 400px;
    border: 1px solid #c8c8c8;
    border-radius: 0.3rem;
    padding: 0.5rem;
    padding-right: 0.7rem;
    justify-content: space-between;
    margin-left: 0.4rem;
}
.file-upload-container-1{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.file-upload-value{
    margin-left: 1rem;
}
.file-upload-container-1:hover{
    cursor: pointer;
    text-decoration: underline;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.Toastify__toast--success {
  background: #fbd3d4 !important;
  color: black !important;
}

.Toastify__toast--error {
  background: #fbd3d4 !important;
  color: black !important;
}

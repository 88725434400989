.student-details-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.student-details-empty p{
    padding: 0.5rem 1rem;
    background: #ffefd1;
    border-radius: 0.6rem;
}
@import url('https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@600&family=Raleway:wght@100&display=swap');
.error-container{
    display: flex;
    align-items: center;
    height:83vh;
}
.error-image img{
    width: 50vw;
}
.error-content{
    font-family: 'Edu QLD Beginner', cursive;
    text-align: center;
    font-size: 2rem;
    margin-right: 1rem;
}
.error-content-heading{
    font-family: 'Edu QLD Beginner', cursive;
    font-size: 8rem;
}
@media only screen and (max-width:702px){
    .error-image{
        display: none;
    }
}
.stationCard {
  width: 20rem;
  height: 15rem;
  border-radius: 1rem;
  background-image: url(./subtle-prism.png);
  background-size: cover;
  color: black;
  overflow: hidden;
  -webkit-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 1rem;
}
.stationCard-content {
  align-self: flex-end;
  background-color: white;
  height: 6rem;
  width: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  transition: height 0.5s, display 2s;
}
.stationCard-image {
  height: 2rem;
  margin-top: auto;
  margin-left: auto;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: none;
  transition: all 2s;
}
.stationCard-id {
  margin: 1rem;
}
.stationCard-id p {
  background-color: #ffd1d1f2;
  display: inline;
  padding: 0.1rem 0.5rem;
  color: #892323;
  border-radius: 0.4rem;
}
.stationCard-name {
  margin: 1rem;
  margin-top: 0rem;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 17rem;
  transition: all 2s;
}
.stationCard:hover .stationCard-content{
    height: 13rem;
}
.stationCard-content:hover {
  height: 13rem;
}
.stationCard-content:hover .stationCard-name {
  text-overflow: unset;
  white-space: unset;
  overflow: auto;
}
.stationCard-content:hover .stationCard-image {
  display: block;
}

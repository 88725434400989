.StationOptions-station-list-container {
    height: 83vh;
    display: flex;
}

.StationOptions-station-list {
    float: center;
    display: flex;
    flex-wrap: wrap;
}

.StationOptions-station {
    background: #ffefd1;
    padding: 1.5rem;
    margin: 0.5rem;
    color: black;
    border-radius: 0.6rem;
    text-align: center;
}

@media only screen and (max-width:702px) {
    .StationOptions-station-list {
        justify-content: center;
    }
}

.station-assignments-list-container {
    height: 83vh;
    display: flex;
    justify-content: space-between;
    overflow: auto;
}

.station-assignments-list {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1vh 0rem;
    margin-right: 0.3rem;
}

.station-assignment-list-image {
    display: flex;
}

.station-assignment-list-image img {
    width: 50vw;
    margin-top: auto;
}

.station-assignment-container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.diary-assignment {
    min-width: 0;
    margin: 0.5rem;
    color: black;
    border-radius: 0.6rem;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    border-left-width: 0.3rem;
    width: 30vw;
    display: flex;
    /* justify-content: space-between;
    align-items: center; */
    margin-bottom: 1vh;
    margin-left: auto;
    transition: all 0.2s ease-in-out;
}

.diary-assignment-container {
    display: flex;
    justify-content: end;
    align-items: center;
}

.station-assignment {
    align-items: center;
    min-width: 0;
    margin: 0.5rem;
    color: black;
    border-radius: 0.6rem;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    border-left-width: 0.3rem;
    width: 40vw;
    display: flex;
    margin-bottom: 1vh;
    margin-left: auto;
    transition: all 0.2s ease-in-out;
}


.station-assignment-new {
    align-items: center;
    min-width: 0;
    margin: 0.5rem;
    color: black;
    text-align: center;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    border-left-width: 0.3rem;
    width: 40vw;
    height: 90px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vh;
    margin-left: auto;
}

.station-assignment-image {
    max-width: 6rem;
    background-color: #fff3dd;
}

.station-assignment-image-announce {
    padding: 1rem;
    max-width: 6rem;
    background-color: #fff3dd;
}

.station-assignment-name {
    min-width: 0;
    display: flex;
    align-items: center;
    margin: 1rem;
    font-size: 1.2rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.station-assignment-lock {
    margin: 0rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.station-assignment-lock:hover {
    cursor: pointer;
}

.station-assignment:hover {
    transform: translateX(-2rem);
}

.arrow {
    margin-left: auto;
    margin-right: 20px;
}

.station-assignment:hover {
    background-color: #fff3dd;
}

@media only screen and (max-width:768px) {

    .station-assignment-container{
        justify-content: center;
    }

    .station-assignment-list-image {
        display: none;
    }

    .station-assignment {
        width: 92vw;
        margin-left: auto;
        margin-right: auto;
    }

    .diary-assignment-container {
        width: 92vw;
    }

    .diary-assignment {
        width: 50vw;
        margin-left: auto;
        margin-right: auto;
    }

    .station-assignment-name {
        width: 100%;
    }

    .station-assignment-image {
        width: 4rem;
    }

    .station-assignment-image-announce {
        width: 4rem;
    }
}
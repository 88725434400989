.pdf-viewer-wrapper {
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
  
.pdf-viewer-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
  
.pdf-viewer-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.pdf-error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 1rem;
    text-align: center;
}